import {
  SettingOutlined,
  ToolOutlined,
  DatabaseOutlined,
  DashboardOutlined,
  LineChartOutlined,
  AppstoreOutlined,
  ShoppingCartOutlined,
  QuestionCircleOutlined,
  DesktopOutlined,
  FileAddOutlined,
  SlidersOutlined,
  TableOutlined,
  UserOutlined,
  BankOutlined,
  MobileOutlined,
  SendOutlined,
  TeamOutlined,
  RocketOutlined,
  WhatsAppOutlined,
  RobotOutlined,
  FileTextOutlined,
  HistoryOutlined,
  NotificationOutlined,
  VideoCameraOutlined,
  AuditOutlined,
  FilePdfOutlined,
  ReconciliationOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const smsNavTree = [
  {
    key: "sms",
    scope: ["sms"],
    featured: true,
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.sms",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [
      {
        scope: ["sms"],
        key: "sms-dashboard",
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: "sidenav.sms-dashboard",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["sms"],
        key: "sms-contacts",
        path: `${APP_PREFIX_PATH}/contacts`,
        title: "sidenav.sms-contacts",
        allowed_paths: [
          { path: `${APP_PREFIX_PATH}/contacts/list`, title: "sidenav.sms-contacts_list" },
          { path: `${APP_PREFIX_PATH}/contacts/manage/.*`, title: "sidenav.sms-contacts_manage" },
        ],
        icon: TeamOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["sms"],
        key: "sms-sms",
        path: `${APP_PREFIX_PATH}/sms`,
        title: "sidenav.sms-sms",
        icon: MobileOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "sms-sms-quick-sms",
            path: `${APP_PREFIX_PATH}/sms/quick-sms`,
            title: "sidenav.sms_quick-sms",
            icon: SendOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "sms-sms-campaigns",
            path: `${APP_PREFIX_PATH}/sms/campaigns`,
            title: "sidenav.sms_campaigns",
            icon: RobotOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "sms-sms-templates",
            path: `${APP_PREFIX_PATH}/sms/templates`,
            title: "sidenav.sms_templates",
            icon: FileTextOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "sms-sms-history",
            path: `${APP_PREFIX_PATH}/sms/history`,
            title: "sidenav.sms_history",
            icon: HistoryOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },

      {
        scope: ["sms"],
        key: "sms-help",
        path: `${APP_PREFIX_PATH}/sms-help`,
        title: "sidenav.sms-help",

        icon: QuestionCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const waBuddyNavTree = [
  {
    key: "wa-buddy",
    scope: ["wa-buddy"],
    featured: true,
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.wa-buddy",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [
      {
        scope: ["wa-buddy"],
        key: "wa-buddy-dashboard",
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: "sidenav.wa-buddy-dashboard",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["wa-buddy"],
        key: "wa-buddy-launches",
        path: `${APP_PREFIX_PATH}/launches`,
        title: "sidenav.wa-buddy-launches",
        allowed_paths: [
          { path: `${APP_PREFIX_PATH}/launches/list`, title: "sidenav.wa-buddy-launches-list" },
          { path: `${APP_PREFIX_PATH}/launches/manage/.*`, title: "sidenav.wa-buddy-launches-manage" },
        ],
        icon: RocketOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["wa-buddy"],
        key: "wa-buddy-groups",
        path: `${APP_PREFIX_PATH}/groups`,
        title: "sidenav.wa-buddy-groups",
        allowed_paths: [
          { path: `${APP_PREFIX_PATH}/groups/list`, title: "sidenav.wa-buddy-groups-list" },
          { path: `${APP_PREFIX_PATH}/groups/manage/.*`, title: "sidenav.wa-buddy-groups-manage" },
        ],
        icon: WhatsAppOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        scope: ["wa-buddy"],
        key: "wa-buddy-help",
        path: `${APP_PREFIX_PATH}/wa-buddy-help`,
        title: "sidenav.wa-buddy-help",

        icon: QuestionCircleOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const defaultNavTree = [
  {
    key: "salespark",
    scope: ["base"],
    //featured: true,
    path: `${APP_PREFIX_PATH}`,
    title: "sidenav.dashboard",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [
      {
        scope: ["salespark"],
        key: "salespark-global",
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: "sidenav.dashboard-global",
        icon: DashboardOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        scope: ["salespark"],
        //featured: true,
        key: "salespark-onboarding",
        path: `${APP_PREFIX_PATH}/onboarding`,
        title: "sidenav.onboarding",
        icon: VideoCameraOutlined,
        breadcrumb: true,
        submenu: [],
      },
      // {
      //   scope: ["salespark"],
      //   key: "salespark-sales",
      //   path: `${APP_PREFIX_PATH}/sales-dashboard`,
      //   title: "sidenav.dashboard-sales",
      //   icon: EuroCircleOutlined,
      //   breadcrumb: true,
      //   submenu: [],
      // },

      // Products
      {
        scope: ["salespark"],
        key: "salespark-products",
        path: `${APP_PREFIX_PATH}/products`,
        title: "sidenav.products",
        icon: AppstoreOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "salespark-products-new",
            path: `${APP_PREFIX_PATH}/products/new`,
            title: "sidenav.products_new",
            icon: FileAddOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "salespark-products-list",
            path: `${APP_PREFIX_PATH}/products/list`,
            allowed_paths: [
              { path: `${APP_PREFIX_PATH}/products/edit/.*`, title: "sidenav.products_edit" },
              { path: `${APP_PREFIX_PATH}/products/view/.*`, title: "sidenav.products_view" },
            ],
            title: "sidenav.products_list",
            icon: TableOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },

      // Orders
      {
        key: "salespark-orders",
        scope: ["salespark"],
        path: `${APP_PREFIX_PATH}/orders`,
        //  allowed_paths: [{ path: `${APP_PREFIX_PATH}/orders/overview`, title: "sidenav.orders_overview" }],
        title: "sidenav.orders",
        icon: ShoppingCartOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "salespark-orders-overview",
            path: `${APP_PREFIX_PATH}/orders/overview`,
            allowed_paths: [{ path: `${APP_PREFIX_PATH}/orders/overview`, title: "sidenav.orders_overview" }],
            title: "sidenav.orders_overview",
            icon: SlidersOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "salespark-orders-buyers",
            scope: ["salespark"],
            path: `${APP_PREFIX_PATH}/buyers`,
            allowed_paths: [
              { path: `${APP_PREFIX_PATH}/buyers/list`, title: "sidenav.buyers_list" },
              { path: `${APP_PREFIX_PATH}/buyers/view-details/.*`, title: "sidenav.buyers_view_details" },
            ],
            title: "sidenav.buyers",
            icon: UserOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "salespark-orders-subscriptions",
            path: `${APP_PREFIX_PATH}/orders/subscriptions`,
            title: "sidenav.orders_subscriptions",
            icon: BankOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },

      // Courses
      {
        key: "salespark-courses",
        scope: ["salespark"],
        path: `${APP_PREFIX_PATH}/courses`,
        ///courses/manage/3040300178/curriculum/chapter/4040300305/lesson/5040300353
        allowed_paths: [
          { path: `${APP_PREFIX_PATH}/courses/manage/(.*)/curriculum`, title: "sidenav.courses_edit" },
          {
            path: `${APP_PREFIX_PATH}/courses/manage/(.*)/curriculum/chapter/(.*)`,
            title: "sidenav.courses_chapter_edit",
          },
          {
            path: `${APP_PREFIX_PATH}/courses/manage/(.*)/curriculum/chapter/(.*)/lesson/(.*)`,
            title: "sidenav.courses_chapter_lesson_edit",
          },
          { path: `${APP_PREFIX_PATH}/courses/manage/(.*)/dashboard`, title: "sidenav.courses_dashboard" },
          { path: `${APP_PREFIX_PATH}/courses/manage/(.*)/settings`, title: "sidenav.courses_settings" },
          { path: `${APP_PREFIX_PATH}/courses/manage/(.*)/statistics`, title: "sidenav.courses_statistics" },
          { path: `${APP_PREFIX_PATH}/courses/manage/(.*)/classes`, title: "sidenav.courses_classes" },

          { path: `${APP_PREFIX_PATH}/courses/view/.*`, title: "sidenav.courses_view" },
        ],
        title: "sidenav.courses",
        icon: DesktopOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "salespark-courses-new",
            scope: ["salespark"],
            path: `${APP_PREFIX_PATH}/courses/new`,
            title: "sidenav.courses_new",
            icon: FileAddOutlined,
            breadcrumb: true,
            submenu: [],
          },

          {
            key: "salespark-courses-list",
            scope: ["salespark"],
            path: `${APP_PREFIX_PATH}/courses/list`,
            title: "sidenav.courses_list",
            icon: TableOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "salespark-courses-comments-manager",
            showNumIncompletedComments: true,
            scope: ["salespark"],
            path: `${APP_PREFIX_PATH}/courses/comments-manager`,
            title: "sidenav.courses_comments_manager",
            icon: AuditOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "salespark-courses-library",
            scope: ["salespark"],
            path: `${APP_PREFIX_PATH}/courses/library`,
            title: "sidenav.courses_library",
            icon: DatabaseOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },

      // Students
      {
        key: "salespark-students",
        scope: ["salespark"],
        path: `${APP_PREFIX_PATH}/students`,
        allowed_paths: [
          { path: `${APP_PREFIX_PATH}/students/list`, title: "sidenav.students_list" },
          { path: `${APP_PREFIX_PATH}/students/details/.*`, title: "sidenav.students_details" },
        ],
        title: "sidenav.students",
        icon: TeamOutlined,
        breadcrumb: true,
        submenu: [],
      },

      // Reports
      {
        key: "salespark-reports",
        scope: ["salespark"],
        path: `${APP_PREFIX_PATH}/reports`,
        title: "sidenav.reports",
        icon: LineChartOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "salespark-reports-transactions",
            path: `${APP_PREFIX_PATH}/reports/transactions`,
            title: "sidenav.reports_transactions",
            icon: SlidersOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "salespark-reports-documents",
            path: `${APP_PREFIX_PATH}/reports/documents`,
            title: "sidenav.reports_documents",
            icon: FilePdfOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "salespark-management",
        scope: ["salespark"],
        path: `${APP_PREFIX_PATH}/management`,
        //  allowed_paths: [{ path: `${APP_PREFIX_PATH}/orders/overview`, title: "sidenav.orders_overview" }],
        title: "sidenav.management",
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "salespark-management-services",
            path: `${APP_PREFIX_PATH}/management/services`,
            title: "sidenav.management_services",
            icon: ReconciliationOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "salespark-management-attendees",
            path: `${APP_PREFIX_PATH}/management/attendees`,
            title: "sidenav.management_attendees",
            icon: IdcardOutlined,
            breadcrumb: true,
            submenu: [],
          },
        ],
      },

      // Work Tools
      {
        key: "salespark-work-tools",
        scope: ["salespark"],
        path: `${APP_PREFIX_PATH}/work-tools`,
        allowed_paths: [
          { path: `${APP_PREFIX_PATH}/work-tools/list`, title: "sidenav.work_tools-list" },
          { path: `${APP_PREFIX_PATH}/work-tools/meta-pixel`, title: "sidenav.work_tools-meta-pixel" },
          { path: `${APP_PREFIX_PATH}/work-tools/ga4-pixel`, title: "sidenav.work_tools-ga4-pixel" },
          { path: `${APP_PREFIX_PATH}/work-tools/webhooks`, title: "sidenav.work_tools-webhooks" },
          { path: `${APP_PREFIX_PATH}/work-tools/invoicexpress`, title: "sidenav.work_tools-invoicexpress" },
          { path: `${APP_PREFIX_PATH}/work-tools/moloni`, title: "sidenav.work_tools-moloni" },
          { path: `${APP_PREFIX_PATH}/work-tools/bill`, title: "sidenav.work_tools-bill" },
          { path: `${APP_PREFIX_PATH}/work-tools/systeme-io`, title: "sidenav.work_tools-systeme-io" },
          { path: `${APP_PREFIX_PATH}/work-tools/mailerlite`, title: "sidenav.work_tools-mailerlite" },
          { path: `${APP_PREFIX_PATH}/work-tools/shorteners`, title: "sidenav.work_tools-shorteners" },
        ],
        title: "sidenav.work_tools",
        icon: ToolOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        key: "salespark-support-request",
        scope: ["base"],
        path: `${APP_PREFIX_PATH}/support-request`,
        title: "sidenav.support-request",
        icon: NotificationOutlined,
        breadcrumb: true,
        new_tab: true,
        submenu: [],
      },
      {
        key: "salespark-feedback",
        scope: ["base"],
        path: `${APP_PREFIX_PATH}/feedback`,
        title: "sidenav.feedback",
        icon: RocketOutlined,
        breadcrumb: true,
        new_tab: true,
        submenu: [],
      },
      {
        key: "salespark-help",
        scope: ["base"],
        path: `${APP_PREFIX_PATH}/help`,
        title: "sidenav.help",
        icon: QuestionCircleOutlined,
        breadcrumb: true,
        new_tab: true,
        submenu: [],
      },
    ],
  },
];

let navigationConfig;
const host = window.location.hostname;

// if (host === "localhost" || host === "beta-app.salespark.io" || host === "app.salespark.io") {
navigationConfig = [...defaultNavTree];

// }

// if (host === "sms.localhost" || host === "beta-sms.salespark.io" || host === "sms.salespark.io") {
//   navigationConfig = [...smsNavTree];
// }

// if (host === "wa-buddy.localhost" || host === "beta-wa-buddy.salespark.io" || host === "wa-buddy.salespark.io") {
//   navigationConfig = [...waBuddyNavTree];
// }

export default navigationConfig;
