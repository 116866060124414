import React from "react";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import AccountClosed from "./account-closed";
import Views from "./views";
import Locked from "./locked";
import SupportRequest from "./supportRequest";

import AccountInactive from "./account-inactive";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import JSONPackage from "../package.json";
import Page404 from "./404";

import "./assets/css/orderBumpShared.css"; //TODO: Fix path
import "./assets/css/custom.css";
import "./custom.css";

import { UpdateNotification } from "react-update-popup";
import "react-update-popup/dist/index.css";

const themes = {
  dark: `/css/dark-theme.css`,
  light: `/css/light-theme.css`,
};

const authBasicData = localStorage.getItem("SalesPark-AuthBasic");
const rollbarConfig = {
  accessToken: "09559ce36a42436d944fa979fb95f7ff",
  environment: process.env.REACT_APP_ENVIRONMENT,
  server: {
    root: window.location.origin,
    branch: "main",
  },
  code_version: JSONPackage.version,
  person: {
    id: authBasicData?.id,
    email: authBasicData?.email,
    username: authBasicData?.name,
  },
};

function App() {
  // Please make sure to call this method only once!
  // Gleap.initialize("waMxrYs4qcDtlBqIab9djCpf6IsMtWkr");
  // Gleap.setLanguage("pt");

  return (
    <div className="App">
      <RollbarProvider config={rollbarConfig}>
        <ErrorBoundary>
          <Provider store={store}>
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={THEME_CONFIG.currentTheme}
              insertionPoint="styles-insertion-point"
            >
              <Router>
                <Switch>
                  <Route exact path="/404" component={Page404} />
                  <Route exact path="/locked" component={Locked} />
                  <Route exact path="/support-request" component={SupportRequest} />
                  <Route exact path="/account-closed" component={AccountClosed} />
                  <Route
                    exact
                    path="/feedback"
                    render={() => {
                      window.location.href = "https://salespark.featurebase.app";
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/help"
                    render={() => {
                      window.location.href = "https://ajuda.salespark.io";
                      return null;
                    }}
                  />
                  <Route exact path="/sms-help" component={SupportRequest} />
                  <Route exact path="/wa-buddy-help" component={SupportRequest} />

                  <Route exact path="/account-inactive" component={AccountInactive} />

                  <Route path="/" component={Views} />
                </Switch>
              </Router>
            </ThemeSwitcherProvider>
          </Provider>

          <UpdateNotification
            title="Nova versão disponível"
            description="Atualiza a página para usares a última versão."
            buttonText="Atualizar página"
          />
        </ErrorBoundary>
      </RollbarProvider>
    </div>
  );
}

export default App;
