import { Result, Card } from "antd";

const AccountClosed = () => {
  return (
    <div className="my-5 container text-center">
      <img src="/img/logo.png" alt="SalesPark" />

      <Card>
        <Result
          status="403"
          title="A tua conta encontra-se encerrada ou temporáriamente encerrada."
          extra={
            <div>
              <p>Para mais informações, contacta-nos através do email suporte@salespark.io</p>
              {/* <Button type="primary" onClick={() => logout()}>
                Terminar Sessão
              </Button> */}
            </div>
          }
        />
      </Card>
    </div>
  );
};

export default AccountClosed;
